import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/uploads';

    async store(data) {
        return this.asFormData().post(this.endpointRoute, data).then(getData);
    }
})();
