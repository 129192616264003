import {DOC, DOCX, GIF, JPEG, JPG, PDF, PNG, XLS, XLSX} from "@/domain/Entities/Upload/extensions";

export const CONTRACT_TEMPLATE_FILE_FORMATS = [
    DOC,
    DOCX,
    PDF
];

export const TASK_TEMPLATE_FILE_FORMATS = [
    DOC,
    DOCX,
    XLSX,
    XLS,
    PDF,
    JPG,
    JPEG,
    GIF,
    PNG,
];
