<template>
    <TemplateFormModals :visible="visible" type="info-request" confirm-button-label="Got it" @close="$emit('close')"
                        @confirm="$emit('confirm', this.checked)">
        <div class="form-check">
            <input v-model="checked" class="form-check-input" type="checkbox"/>
            <label class="form-check-label" for="flexCheckDefault">Do not show again</label>
        </div>
    </TemplateFormModals>
</template>

<script>
import TemplateFormModals from "@/views/Templates/Template/Components/TemplateFormModals.vue";

const INFO_ACKNOWLEDGMENT = 'info-acknowledgment';
const INFO_REQUEST = 'info-request';

const EXPECTED_TYPES = [INFO_ACKNOWLEDGMENT, INFO_REQUEST];

export default {
    name: 'OnboardingTemplateFormModals',
    emits: [
        'close',
        'confirm'
    ],
    components: {TemplateFormModals},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            validator: (value) => EXPECTED_TYPES.includes(value)
        }
    },
    data() {
        return {
            checked: false
        };
    }
}
</script>

<style scoped lang="scss">

</style>
